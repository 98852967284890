<div *ngIf="type == 0" [style.background-color]="item.customField15"
     style="border-radius: 12px; padding: 10px;">
  <div [formGroup]="firstFormGroup" autocomplete="off">
    <div class="form-container">
      <div class="result padding-10">
    

<div *ngIf="type==0"  [style.background-color]="item.customField15" style="border-radius: 12px;">
  <div class="input-row backg" [formGroup]="firstFormGroup" autocomplete="off">
    <div fxLayout="column">
      <div class="result padding-10" fxLayout="column">
        
        <h1 *ngIf="item.type=='scrolltextwithform'" fxLayoutAlign="center">{{ item.title }}</h1>
        <div class="input-row" fxLayout="row wrap" fxLayoutGap="10px grid">
        <mat-form-field>
            <input *ngIf="field.type === 'text'"
                   [autocomplete]="'off'"
                   trim="blur"
                   matInput
                   [formControlName]="field.controlName"
                   required />

            <textarea *ngIf="field.type === 'textarea'"
                      trim="blur"
                      matInput
                      [formControlName]="field.controlName"
                      required></textarea>

            <mat-error *ngIf="firstFormGroup.controls[field.controlName]?.errors?.required">
              Please fill out this field.
            </mat-error>
          </mat-form-field>

        </div>

      </div>
    </div>

  </div>

  <!-- Submit Button in Full Width and Last Row -->
  <div class="input-row1 padding-b10" fxLayout="row" style="width: 100%;">
    <button class="primary" style="color:white; width: 100%;margin:0px 15px" mat-flat-button [disabled]="text != 'Submit'" (click)="createContact()">
      {{text}}
      <mat-icon *ngIf="text != 'Submit'">
        <mat-spinner class="primary" diameter="20"></mat-spinner>
      </mat-icon>
    </button>
  </div>
</div>




<div *ngIf="type==1" [style.background-color]="item.customField15" style="border-radius: 12px;">
  <div class="input-row backg" autocomplete="off">
      <div fxLayout="column">
          <div class="result padding-10" fxLayout="column">
              <div class="input-row1 padding-b10" fxLayout="row"
               [fxLayoutAlign]="item.customField12" fxflex="100" fxLayoutGap="10px">
                  <h1 style="width: 100%" >
                      <calsoft-editor-text [data]="data" [text]="input" [align]="item.customField34"           [style.font-size]="item.customField39" [ngStyle.lt-md]="{ 'font-size': item.customField40 }">
                      </calsoft-editor-text>
                  </h1>
              </div>


              <div *ngIf="init">
                  <dynamic-form  [layout]="alignMent" [SuccessMessage]="customField28" [FailerMessage]="customField29" [redirectUrl]="customField10" [customField13]="customField13" [customField5]="customField5" [fields]="regConfig" [newFormField]="customField8" (submit)="submit($event)">
                  </dynamic-form>
              </div>
          </div>
      </div>

  </div>
  
</div>
