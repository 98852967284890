import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
@Injectable({
  providedIn: 'root'
})
export class ServiceWorkerService {

  constructor(public updates: SwUpdate) {

    if (updates.isEnabled) {
    }
   }

   public checkForUpdates(): void {
    this.updates.versionUpdates.subscribe(event => this.promptUser());
  }

  private promptUser(): void {
    this.updates.activateUpdate().then(() => document.location.reload());
  }
}
