import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule, HammerModule, Title } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatToolbarModule} from '@angular/material/toolbar';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

import { MatSidenavModule } from '@angular/material/sidenav';
import { PipesModule } from './theme/pipes/pipes.module';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { CalsoftInterceptor } from './shared/utils/calsoft-interceptor';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { LoaderService } from './services/loader.service';
import { AuthService } from './services/auth.service';
import { MAT_MENU_SCROLL_STRATEGY } from '@angular/material/menu';
import { menuScrollStrategy } from './shared/utils/scroll-strategy';
import { Overlay } from '@angular/cdk/overlay';
import { SharedModule } from './shared/shared.module';
import { ComponentModule } from './theme/components/component.module';
import { MainComponent } from './pages/main/main.component';
import { BreadcrumbModule } from './theme/breadcrumb/breadcrumb.module';
import { PromptComponent } from './prompt/prompt.component';
import { PwaService } from './services/pwa.service';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { ShareChatTrackingService } from './services/share-chat-tracking.service';
import { CustomFlexLayoutModule } from 'src/app/flex-layout/custom-flex-layout.module';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DirectiveModule } from './shared/utils/directive/directive.module';


@NgModule({ declarations: [
        AppComponent,
        MainComponent,
        PromptComponent,
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        MatToolbarModule,
        MatIconModule,
        HammerModule,
        MatSnackBarModule,
        BreadcrumbModule,
        CustomFlexLayoutModule,
        MatIconModule,
        MatFormFieldModule,
        MatInputModule,
        FormsModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
            // Register the ServiceWorker as soon as the application is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000'
        }),
        MatSidenavModule,
        PipesModule,
        MatDialogModule,
        SharedModule,
        ComponentModule,
        MatBottomSheetModule,
        DragDropModule,
        DirectiveModule], providers: [
        AuthService,
        LoaderService,
        ShareChatTrackingService,
        Title,
        { provide: MAT_MENU_SCROLL_STRATEGY, useFactory: menuScrollStrategy, deps: [Overlay] },
        { provide: HTTP_INTERCEPTORS, useClass: CalsoftInterceptor, multi: true },
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule { }
